<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/position">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/position"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
                class="step0"
            >
                <template #content>
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="cargos-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>

                    <div class="">
                        <div class="field field-checkbox">
                            <label for="ativo" class="mr-2 ml-0">Ativo</label>
                            <InputSwitch id="ativo" v-model="form.ativo" />
                        </div>
                        <div class="field">
                            <span :style="{ color: 'red' }">*</span>
                            <label for="customer_id">Cliente</label>
                            <DropdownClienteInscricao
                                id="customer_id"
                                v-model="form.customer"
                                :idValue="form.customerId"
                                :class="{ 'p-invalid': submitted && !form.customerId }"
                            />
                            <small class="p-error" v-if="submitted && !form.customerId">Cliente é obrigatório.</small>
                        </div>

                        <div class="field">
                            <span :style="{ color: 'red' }">*</span>
                            <label for="name">Cargo</label>
                            <InputText
                                id="name"
                                v-model.trim="form.name"
                                required="true"
                                autofocus
                                autocomplete="off"
                                :class="{ 'p-invalid': submitted && !form.name }"
                                @change="verificaNomeDuplicado"
                            />
                            <small class="p-error" v-if="submitted && !form.name">Nome é obrigatório.</small>
                        </div>

                        <div class="grid">
                            <div class="field col-6">
                                <label for="cbo">CBO</label>
                                <DropdownCbo id="cbo" v-model="form.cbo" :value="form.cbo"></DropdownCbo>
                            </div>

                            <div class="field col-6">
                                <label for="gfipAposentadoriaEspecial">GFIP</label>

                                <Dropdown
                                    id="cbo"
                                    v-model="form.gfipAposentadoriaEspecial"
                                    :options="GFIP"
                                    optionLabel="label"
                                    optionValue="label"
                                ></Dropdown>
                            </div>
                        </div>

                        <div class="field">
                            <div class="text-800 mb-1">
                                <span v-tooltip="'Descrição das atividades exercidas pelo colaborador.'" class="text-primary pi pi-info-circle">
                                </span>
                                <span :style="{ color: 'red' }">*</span>
                                <label for="description">Descrição da função (e-Social)</label>
                            </div>
                            <Textarea
                                v-model.trim="form.description"
                                autocomplete="off"
                                :autoResize="true"
                                rows="3"
                                :class="{ 'p-invalid': submitted && !form.description }"
                            />
                            <small class="p-error" v-if="submitted && !form.description">Descrição é obrigatório.</small>
                        </div>

                        <div class="field">
                            <label for="orientation">Orientação (ASO)</label>
                            <Textarea v-model.trim="form.orientation" autocomplete="off" :autoResize="true" rows="3" />
                        </div>
                        <div class="grid">
                            <div class="field col-3">
                                <label for="codigoSoc">Código SOC</label>
                                <InputText id="codigoSoc" v-model.trim="form.codigoSoc" autocomplete="off" />
                            </div>

                            <div class="field col-3">
                                <label for="codigoExterno">Código externo</label>
                                <InputText id="codigoExterno" v-model.trim="form.codigoExterno" autocomplete="off" />
                            </div>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getCurrentCompany } from '@/services/store';
import DropdownCbo from './components/DropDownCbo.vue';
import gfipEnum from '../../enums/GfibEnum';
import DropdownClienteInscricao from '../clientes/components/DropdownClienteInscricao.vue';
import AppInfoManual from '../../components/AppInfoManual.vue';

export default {
    components: {
    DropdownCbo,
    DropdownClienteInscricao,
    AppInfoManual
},
    data() {
        return {
            form: {
                ativo: true
            },
            submitted: false
        };
    },
    created() {
        this.service = new BaseService('/customer/positions');
        this.gfipOptions();
    },
    async mounted() {
        this.loadCustomers();
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar cargo' : 'Adicionar cargo';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        }
    },
    watch: {
        'form.customer'() {
            this.form.customerId = this.form.customer?.id;
        },
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        }
    },
    methods: {
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave(response) {
            if (response.data.statusSincronizacaoSoc == 'ERRO') {
                this.$toast.add({ severity: 'error', summary: 'Erro na integração com SOC. Verifique o log de erros.', life: 3000 });
            }
            this.submitted = false;
        },
        async onLoadDataEdit(data) {
            this.form = data;
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }

            return cbSuccess();
        },
        gfipOptions() {
            this.GFIP = Object.keys(gfipEnum).map(function (type) {
                return { label: `${gfipEnum[type]}`, value: `${type}` };
            });
        },
        async verificaNomeDuplicado() {
            try {
                if (!this.form.customerId) {
                    return;
                }
                const { data } = await this.$service.findAll({ customerId: [this.form.customerId] });
                if (data.items.find((value) => value.name == this.form.name)) {
                    this.$toast.add({ severity: 'error', summary: 'Um cargo com este nome ja existe no cadastro deste cliente!', life: 5000 });
                    this.form.name = '';
                }
            } catch (error) {
                return error;
            }
        },
        hasError() {
            if (!this.form.customerId) return true;
            if (!this.form.name) return true;
            if (!this.form.description) return true;
        }
    }
};
</script>

<style scoped lang="scss">
#customer_id {
    border-radius: 6px;
}
</style>
